.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: #282c34;
}

.App-link {
  font-family: sans-serif;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  size: 14px;
  text-decoration: none;
  letter-spacing: 0.5px;
  line-height: 1.6em;
  color: #282c34;
}

.Profiler-link {
  color: #282c34;
}


.Profiler-link-fadeOut{
  color: #282c34;
  font-family: sans-serif;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  size: 14px;
  text-decoration: none;
  letter-spacing: 0.5px;
  line-height: 1.6em;
  opacity:0;
  width:100%;
  height:100%;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}
.Profiler-link-fadeIn{
  color: #282c34;
  font-family: sans-serif;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  size: 14px;
  text-decoration: none;
  letter-spacing: 0.5px;
  line-height: 1.6em;
  opacity:1;
  width:100%;
  height:100%;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Bb-gif {
  height: 60vmin;
  pointer-events: none;
}

.Bb-logo {
  margin: 20px;
  height: 8vmin;
  pointer-events: none;
}
